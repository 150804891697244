import React, { useReducer, useEffect, useState, useContext } from "react"
import ReactDOM from "react-dom/client"
import { Route, Routes, BrowserRouter as Router, useParams, useNavigate } from "react-router-dom"
// import createBrowerHistory from "history/createBrowserHistory"
import { createBrowserHistory as createBrowerHistory } from 'history';
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing";
// import { Integrations } from "@sentry/tracing"
import { Font } from "@react-pdf/renderer"
import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent as logEventFirebase } from "firebase/analytics"

import { Global, css, jsx } from "@emotion/react"
import { isAndroid, isIOS } from "react-device-detect"

// MODULE IMPORTS
import Assessment from "./Modules/Assessment/Assessment"
import Summary from "./Modules/summary/Summary"
import Home from "./Modules/home/Home"
import HomeDummy from "./Modules/home/HomeDummy"
import PAP from "./Modules/PAP/PAP"
import GOAL from "./Modules/Goals/Goals"
import EducationModule from "./Modules/Education/EducationModule"
import EducationModuleGeneral from "./Modules/Education/EducationModuleGeneral"
import SummaryDetails from "./Modules/summary/SummaryDetails"
import Dashboard from "./Modules/home/Dashboard"
import SupportResources from "./Modules/Education/SupportResources"
import { SummaryDetailsPrintView } from "./Modules/summary/SummaryDetailsPrintView"
import Printpap from "./Modules/PAP/Printpap"

// COMPONENT IMPORTS
import DownloadApp from "./components/popups/mobile/DownloadApp"
import { Forms } from "./components/form/Forms"
import Profile from "./components/profile/profile"
import { PrivateRoute } from "./components/routes/PrivateRoute"
import AboutUs from "./components/about/AboutUs"
import PrivacyPolicyPage from "./components/privacy/PrivacyPolicyPage"
import TermAndCondition from "./components/termCondition/termCondition"
import { ClientAccessRoute } from "./components/routes/ClientAccessRoute"
import UserResults from "./components/privacy/UserResults"

// STORE IMPORTS
import StateContextProvider, { StateContext } from "./store/state/contexts"
import { StateContext as FormContext } from "./store/form/contexts"
import appReducer from "./store/state/reducers"
import formAppReducer from "./store/form/reducers"
import StateContextHook from "./store/state/contexts"

// CUSTOM IMPORTS
import { Auth } from "./api/Auth"
import Roboto from "./assets/fonts/roboto-medium-webfont.ttf"
import publicRoutes from "./routes"
import { baseUrl } from "./lib/constants"
import OldSiteRedirect from "./redirects/OldSiteRedirect"
/** @jsx jsx */
import { getWhetherShowedMobileAppLinks } from "./utils/displayMobileAppLinks"
import initialState2 from './static/initialState2'
import "./i18n"
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from "react-i18next";


Sentry.init({
  dsn: "https://8c749d52e97a4c80a816d1253756f5b0@o1237001.ingest.sentry.io/6387339",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

//  import Signin from "./Modules/Signin";
//  import Signup from "./Modules/Signup";
//  import ClientAccessCode from "./Modules/ClientAccessCode"

const history = createBrowerHistory({ forceRefresh: false })

// Initialize Firebase
const firebaseConfig = require("./config")
const firebaseApp = firebase.initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
// analytics()
function logEvent(event) {
  // [START analytics_log_event]
  logEventFirebase(analytics, event)
  // [END analytics_log_event]
}

// Example logging function for user login
function logUserLogin(user) {
  // [START analytics_log_event_custom_params]
    logEventFirebase(analytics, analytics.EventName.LOGIN, { username: user })
  // [END analytics_log_event_custom_params]
}

logEvent()
export default firebaseApp
export { logEvent, logUserLogin }

const RouteWrapper = ({component: Component, ...props}) => {
  let params = useParams()
  let navigate = useNavigate()
  return <Component {...props} match={{ params }} navigate={navigate} />
}

const App = () => {
  const stateContext = StateContextHook()
  const [state, dispatch] = useReducer(formAppReducer, initialState2)

  const auth = () => {
    try {
      Auth.init()
        .then((res) => {
          if (res && res.status && res.status === 200) {
            stateContext.dispatch({
              type: "HANDLE_AUTH_SUCCESS",
              payload: res.data.email,
            })
          } else {
            throw "Error"
          }
        })
        .catch((err) => {
          console.log({ err })
          stateContext.dispatch({
            type: "HANDLE_AUTH_ERROR",
          })
        })
      fetch(baseUrl + `/user-profile`, {
        credentials: "include",
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch({ type: "SET_ACCESS_CODE", payload: res.accessId })
          logEvent("auth /user-profile successful")
        })
        .catch((err) => {
          console.log(err)
          logEvent("auth /user-profile unsuccessful")
        })
    } catch (err) {
      console.log({ err })
    }
  }

  useEffect(() => {
    auth()
    Font.register({
      family: "Roboto",
      src: Roboto,
    })
    try {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual"
      }

      window.scrollTo(0, 0)
    } catch {}
  }, [])

  // useEffect(() => {
  //   if (!getWhetherShowedMobileAppLinks() && (isAndroid || isIOS)) {
  //     window.location.replace(`https://mensante-assessment.ca/DownloadApp`)
  //   }
  // }, [])
  if(!localStorage.getItem('env') || !localStorage.getItem('envConfig') || localStorage.getItem('env') === 'null' || localStorage.getItem('envConfig') === 'null') {

    return (
      <StateContext.Provider value={stateContext}>
        <Router>
          <Routes>
            <Route path="*" element={<HomeDummy />} />
            <Route
              path="/:client"
              element={<RouteWrapper component={OldSiteRedirect} />}
            />
            {/* <Route path="/Signin" component={Signin} /> 

          <Route path="/Signup" component={Signup} /> 
          <Route path="/ClientAccessCode" component={ ClientAccessCode } /> */}
          </Routes>
        </Router>
      </StateContext.Provider>
    )
  }
  
  return (
    <>
      <Global
        styles={css`
          html {
            padding: unset;
            margin: unset;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          body {
            margin: 0;
            font-size: 16px;
          }

          *,
          *:before,
          *:after {
            box-sizing: border-box !important;
          }

          @media (max-width: 800px) {
            body {
              font-size: 0.7em;
            }
          }
        `}
      />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <StateContext.Provider value={stateContext}>
            <FormContext.Provider value={{ state, dispatch }}>
              <div>
                <Router history={history}>
                  <Routes>
                    <Route exact path={publicRoutes.home.path} element={<Home />} />
                    <Route path="/about" element={<AboutUs/>} />
                    <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
                    <Route path="/termcondition" element={<TermAndCondition />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/userresults" element={<UserResults />} />

                    {/* Private Routes */}
                    <Route path="/profile" element={<PrivateRoute component={Profile} />} />
                    <Route path="/newforms" element={<PrivateRoute component={withTranslation()(Forms)} />} />
                    <Route path="/pap/:id" element={<PrivateRoute component={PAP} />} />
                    <Route path="/pap" element={<PrivateRoute component={PAP} />} />
                    <Route path="/goal" element={<PrivateRoute component={GOAL} />} />
                    <Route path="/printpap/:id" element={<PrivateRoute component={withTranslation()(Printpap)} />} />

                    {/* ClientAccess Routes */}
                    <Route path="/assessment" element={<ClientAccessRoute component={Assessment} accessId={state.personalInfo.organizationAccessCode} />} />
                    <Route path="/summary/:id" element={
                        <ClientAccessRoute
                          component={Summary}
                          accessId={state.personalInfo.organizationAccessCode}
                        />
                      } 
                    />
                    <Route path="/Education" element={
                        <ClientAccessRoute
                          component={EducationModule}
                          accessId={state.personalInfo.organizationAccessCode}
                        />
                      } 
                    />
                    <Route path="/Education/:id" element={
                        <ClientAccessRoute
                          component={EducationModule}
                          accessId={state.personalInfo.organizationAccessCode}
                        />
                      } 
                    />
                    <Route path="/SummaryDetails/:id" element={
                        <ClientAccessRoute
                          component={SummaryDetails}
                          accessId={state.personalInfo.organizationAccessCode}
                        />
                      } 
                    />
                    <Route path="/SupportResources/COVID" element={
                        <ClientAccessRoute
                          component={SupportResources}
                          accessId={state.personalInfo.organizationAccessCode}
                        />
                      } 
                    />
                    <Route path="/SummaryDetailsPrintView/:id" element={
                        <ClientAccessRoute
                          component={SummaryDetailsPrintView}
                          accessId={state.personalInfo.organizationAccessCode}
                        />
                      } 
                    />


                    <Route
                      path="/:client"
                      element={<RouteWrapper component={OldSiteRedirect} />}
                    />
                    {/* <Route path="*" element={<Home />} /> */}
                  </Routes>
                </Router>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </FormContext.Provider>
          </StateContext.Provider>
      </GoogleOAuthProvider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);
