import React, { Component, createRef, useContext } from "react"
import ReactSlidingPane from "../../components/bottom-slider/ReactSlidingPane"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import "../../css/Home.scss"
import "../../css/newsCarousal.scss"
import { Link } from "react-router-dom"
import { baseUrl, loginUrl } from "../../lib/constants"
import { checkdevice } from "../../utils/checkdevice"
import { ClientAccessId } from "../../components/popups/accessCode/ClientAccessId"
import Header from "../../components/navbar/Header"
import TestimonialsCarousel from "./TestimonialsCarousel"
import { withTranslation } from "react-i18next"
// import "../css/animate.scss";
import { NewsCarousel } from "./NewsCarousel"
import { HashLink } from "react-router-hash-link"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"
import { logEvent } from "../../index"
import axios from "axios"
import Footer from "../../components/footer/Footer"
import { getWhetherShowedMobileAppLinks } from "../../utils/displayMobileAppLinks"
import { isAndroid, isIOS } from "react-device-detect"
import AssessmentGap from "../../components/popups/accessCode/FollowUpGap"
import { StateContext } from "../../store/state/contexts"
import moment from 'moment';
import LoginPopup from "../../components/popups/accessCode/LoginPopup";
import '../..'
var store = require("store")

const lngs = {
  en: { nativeName: "En" },
  fr: { nativeName: "Fr" },
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.modelRef = createRef()
    this.state = {
      auth: false,
      showClientAccessPopUp: false,
      inProgress: false,
      email: '',
      error: '',
      loading: false,
      success: false,
      hasClient: false,
      mainLoader: true,
      showAssessmentGapPopup: false
    }
  }

  setshowAssessmentGapPopup = (flag) => {
    this.setState({
      showAssessmentGapPopup: flag
    })
  }

  closeAssessmentGapPopup = () => {
    this.setshowAssessmentGapPopup(false)
  }


  recordHits = () => {
    console.log(window.location.host)
    axios.post(`${baseUrl}/web-visit`, { host: window.location.host, env: localStorage.getItem('env') })
  }


  componentDidMount() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const nonce = urlParams.get("code")
    if (nonce === "clinadvance")
      window.open("https://feelingbetternow.com/", "_self")
    checkdevice(this.props)
    let promises = [this.checkIsUserAuthorized(),
    this.checkAssessmentInProgress()]

    this.recordHits()

    Promise.all(promises).then(() => {
      this.setState({ mainLoader: false })
    })
      .catch(error => {
        this.setState({ mainLoader: false })
      })
    this.props.i18n.changeLanguage(store.get('lang') || 'en')
    // if (!getWhetherShowedMobileAppLinks() && (isAndroid || isIOS)) {
    //   window.location.replace(`/DownloadApp`)
    // }
  }

  // useEffect(() => {
  //   if (!getWhetherShowedMobileAppLinks() && (isAndroid || isIOS)) {
  //     window.location.replace(`https://mensante-assessment.ca/DownloadApp`)
  //   }
  // }, [])

  logOut = () => {
    fetch(baseUrl + "/logout", { credentials: "include", mode: "cors" }).then(
      (response) => {
        if (response) {
          this.setState({ auth: false })
          this.props.history.push("/")
          logEvent("logOut /logOut successful")
        } else logEvent("logOut /logout failed")
      }
    ).catch((err) => {
      console.log("Home.jsx", err);
    })
  }

  checkAssessmentInProgress = async () => {
    fetch(baseUrl + "/assessment/inprogress", {
      credentials: "include",
      mode: "cors",
      method: "GET",
    }).then((res) => res.json())
      .then(res => {
        if (res == "") {
          this.setState({ inProgress: false })
          logEvent("checkAssessmentInProgress /assessment/inprogress failed")
        } else {
          this.setState({ inProgress: true })
          logEvent("checkAssessmentInProgress /assessment/inprogress successful")
        }
      })
      .catch((err) => {
        console.log("Home.jsx", err);
      })
  }

  checkIsUserAuthorized() {
    return fetch(baseUrl, { credentials: "include", mode: "cors" })
      .then((response) => {
        logEvent("checkIsUserAuthorized / successful")
        if (response.status === 200) {
          this.setState({ auth: true })
          this.checkClientID()
        } else this.setState({ auth: false })
      })
      .catch((err) => {
        console.log("Home.jsx", err);
        logEvent("checkIsUserAuthorized / failed")
        this.setState({ auth: false })
      })
  }

  handleClosePopup = () => {
    this.setState({ showClientAccessPopUp: false })
  }

  checkClientID = async () => {
    try {
      const res = await fetch(baseUrl + "/user/accesscode", {
        credentials: "include",
        mode: "cors",
        method: "GET",
      })
      const assementCode = await res.json()
      if (!assementCode || (typeof assementCode === 'object' && Object.keys(assementCode).length === 0)) {
        logEvent("checkClientID /user/accesscode successful")
        this.setState({ showClientAccessPopUp: true, hasClient: false })
      } else {
        this.setState({ hasClient: true })
        logEvent("checkClientID /user/accesscode failed")
      }
    } catch (err) {
      console.log("Home.jsx", err);
    }

  }

  submitNewsletter = async (e) => {
    e.preventDefault()
    if (!this.state.email) {
      return this.setState({ error: 'Please Enter Email', success: '' })
    }
    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegexp.test(this.state.email)) {
      return this.setState({ error: 'Please Enter Valid Email' })
    }
    this.setState({ loading: true, error: '', success: '' });
    try {
      let response = await axios.post(`${baseUrl}/email/newsletter`, { email: this.state.email });
      response = response.data;
      this.setState({ success: response?.message || 'Successfully subscribed to our newsletter', loading: false, error: '', email: '' });
    } catch (error) {
      console.log(error, ' <=== newsletter error...');
      this.setState({ error: error?.response?.data?.message || error.message, loading: false, success: '', email: '' });
    }
  }

  showClientPopUp = () => {
    this.setState({ showClientAccessPopUp: true })
  }

  setshowLoginPopup = (flag) => {
    this.setState({ showLoginPopup: flag })
  }
  closeLoginPopup = () => {
    this.setshowLoginPopup(false)
  }

  render() {
    const { auth, showClientAccessPopUp, mainLoader, showLoginPopup } = this.state
    const { t, i18n } = this.props
    const { closeLoginPopup, setshowLoginPopup } = this

    if (mainLoader)
      return <>
        <div className="text-center" style={{ display: 'flex', height: '100vh', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <div
            className="spinner-border assessmentSpinner"
            role="status"
            style={{
              width: '2rem',
              height: '2rem',
              border: '0.25em solid currentColor',
              borderRightColor: 'transparent'
            }}
          ></div>
          <span className="h1 ml-2">{t("summaryPage.loading")}</span>
        </div>
      </>

    const { detailsData, detailsDataLoading } = this.props.stateContext

    const { setshowAssessmentGapPopup, closeAssessmentGapPopup } = this;
    const { showAssessmentGapPopup } = this.state

    let allowAssessment = false

    // console.log('detailsData check ', this.props.stateContext, this.props.stateContext.auth.isLoggedIn, detailsData, detailsData?.user, detailsData?.user?.lastAssessment)
    if ((detailsData && detailsData.user && detailsData.user._id) || this.props.stateContext.auth.isLoggedIn) {
      if (detailsData && detailsData.user && detailsData.user.lastAssessment) {
        if (moment().isAfter(moment(detailsData.user.lastAssessment).add(14, 'days'))) {
          allowAssessment = true;
        }
      } else {
        allowAssessment = true;
      }
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <div className="HomePG">
          <LoginPopup
            i18n={i18n}
            t={t}
            show={showLoginPopup}
            handleClose={closeLoginPopup}
            onHide={closeLoginPopup}
            lastAssessment={detailsData?.user?.lastAssessment}
          />
          <AssessmentGap
            i18n={i18n}
            t={t}
            show={showAssessmentGapPopup}
            handleClose={closeAssessmentGapPopup}
            onHide={closeAssessmentGapPopup}
            lastAssessment={detailsData?.user?.lastAssessment}
          />
          <ClientAccessId
            i18n={i18n}
            t={t}
            show={showClientAccessPopUp}
            handleClose={this.handleClosePopup}
            onHide={this.handleClosePopup}
          />
          <div className="home-banner-section">
            <div className="container">
              {/* <img
              src={require("../../assets/images/banner1.png")}
              alt=""
              style={{ width: "100%" }}
            /> */}
              <Header fromHome={true} inProgress={this.state.inProgress} hasClient={this.state.hasClient} showClientPopUp={this.showClientPopUp} />
              <div className="fbnIntro-fbnIntro-home">
                <p className="homeHeadline wow fadeInUp">
                  <span> {t("fbnIntro.headline")}</span>
                </p>
                {/* <p className="homeText">
                  <span className="dsblText">{t("fbnIntro.description")}</span>
                </p> */}
                <ul className="home-page-list mt-5">
                  <li>{t("fbnIntro.list1")}</li>
                  <li>{t("fbnIntro.list2")}</li>
                  <li>{t("fbnIntro.list3")}</li>
                  <li>{t("fbnIntro.list4")}</li>
                  <li>{t("fbnIntro.list5")}</li>
                  <li>{t("fbnIntro.list6")}</li>
                </ul>
                {detailsDataLoading ?
                  <button className="btn homeButtons">
                    {t('summaryPage.loading')}
                  </button>
                  :
                  auth ? (
                    this.state.hasClient ?
                      (true || allowAssessment || this.state.inProgress ?
                        (<Link to="/assessment">
                          <button className="btn homeButtons">
                            {this.state.inProgress
                              ? t("fbnIntro.buttonText2AltHome")
                              : t("fbnIntro.buttonText2")}
                          </button>
                        </Link>) :
                        <button className="btn homeButtons" onClick={(e) => {
                          e.preventDefault()
                          setshowAssessmentGapPopup(true)
                        }}>
                          {this.state.inProgress
                            ? t("fbnIntro.buttonText2Alt")
                            : t("fbnIntro.buttonText2")}
                        </button>)
                      : <button onClick={this.showClientPopUp} className="btn homeButtons">
                        {this.state.inProgress
                          ? t("fbnIntro.buttonText2Alt")
                          : t("fbnIntro.buttonText2")}
                      </button>
                  ) : (
                    // <a className="btn homeButtons wow fadeInUp" href="#personalized-demo">
                    // <HashLink className="btn homeButtons" to="/about#contact">
                    //   {t("fbnIntro.buttonText1")}
                    // </HashLink>
                    <button
                      className="btn homeButtons"
                      onClick={() => setshowLoginPopup(true)}
                    >
                      {t("fbnIntro.buttonText2Home")}
                    </button>
                  )
                }
              </div>
            </div>
          </div>
          
          <div className="how-we-work-block">
          <div className="container">
              <div className="title-top-section">
                {/* <h4>{t('homePage.sections.whatWeDo')}</h4> */}
                <h2 className="homeHeadline">
                  {t("fbnAboutUs2.headline1")}
                  {/* <br></br> */}
                  <span>FeelingBetterNow®</span>
                  {t("fbnAboutUs2.headline2")}
                </h2>
              </div>
              <div className="work-two-block">
                <div className="fbnAboutUs_2 wow fadeInUp"
                >
                  <div className="fbnAboutUs_3 wow fadeInUp" style={{ top: "77%" }}>
                    <div className="wwdtle title-center" >
                      <h3>{t("fbnAboutUs2.headline3")}</h3>
                    </div>
                    <p className="homeText">{t("fbnAboutUs2.description")}</p>
                    <HashLink to="/assessment" target={"_blank"}>
                      <button
                        className="btn homeButtons"
                        style={{ marginTop: "6px" }}
                      >
                        {t("fbnAboutUs2.buttonText")}
                      </button>
                    </HashLink>
                  </div>
                </div>
                <div className="fbnVideo wow fadeInUp">
                  {/* <iframe
                    id="fbnVideo"
                    name="video"
                    title="homePageVideo"
                    src="https://player.vimeo.com/video/495541623"
                    width="640"
                    height="564"
                    allow="autoplay; fullscreen"
                  /> */}
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/7_JitqfKsIY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />
                  {/* {(store.get('lang') || 'en') === 'en' ?
                    <iframe
                      id="fbnVideo"
                      name="video"
                      title="homePageVideo"
                      src="https://www.youtube.com/watch?v=-XuddNEAYiQ"
                      width="640"
                      height="564"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen 
                    />
                  :
                    <iframe
                      id="fbnVideo"
                      name="video"
                      title="homePageVideo"
                      src="https://player.vimeo.com/video/495541623"
                      width="640"
                      height="564"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  } */}
                </div>
              </div>
            </div>

          </div>

          <div className="footer">
            <Footer />
          </div>
          {/* <div className="downButtons">
            {Object.keys(lngs).map((lng) => (
              <button
                key={lng}
                className="lngBtn"
                style={{
                  fontWeight: i18n.language === lng ? "bold" : "normal",
                  outline: 0,
                }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </button>
            ))}
          </div> */}
        </div>
      </ErrorBoundary>
    )
  }
}

function NewHome(props) {
  const { state } = useContext(StateContext)
  return <Home {...props} stateContext={state} />
}

export default withTranslation()(NewHome)
